import type { AxiosResponse } from "axios";
import axios from "axios";
import type { QueryResult } from "react-query";
import { useQuery } from "react-query";

export interface CartActivitiesData {
  activityIds: string[];
}

const getCartActivities = async (): Promise<CartActivitiesData> => {
  const response: AxiosResponse<CartActivitiesData> = await axios.get(
    "/api/cart/activities"
  );

  return response.data;
};

export default function useCartActivities(): QueryResult<{
  activityIds: string[];
}> {
  return useQuery(["cartActivities"], getCartActivities);
}
