import { SubmitButton } from "components/form/SubmitButton";
import { Loader } from "components/Loader";
import { ActivitiesListingNoResults } from "components/site/ActivitiesListingNoResults";
import { ActivityListItem } from "components/site/ActivityListItem";
import { fetchActivityGroupsAvailability } from "features/activities/utils/fetchActivityGroupsAvailability";
import { getActivityGroupIdsFromActivitiesListingResponse } from "helpers/activity";
import { getHasFiltersApplied } from "helpers/field";
import useActivitiesListing from "hooks/useActivitiesListing";
import React, { useEffect } from "react";
import type { QueryResult } from "react-query";
import { useQuery } from "react-query";
import type { SessionAvailabilityHash } from "types/model/activity";
import type { Client } from "types/model/client";
import type { FieldFilters } from "types/model/field-data";
import { cn } from "utils/cn";

export const HomeActivitiesListing = ({
  shouldUseGreyBg,
  filters,
  isIframe,
  listingPath,
  client
}: {
  shouldUseGreyBg: boolean;
  filters: FieldFilters;
  isIframe: boolean;
  listingPath: string;
  client: Client;
}) => {
  const activitiesListingQueryInfo = useActivitiesListing(
    JSON.stringify(filters),
    listingPath
  );

  const { data: activityAvailabilityData } = useQuery(
    "activityAvailability"
  ) as QueryResult<SessionAvailabilityHash>;

  useEffect(() => {
    if (activitiesListingQueryInfo.data) {
      const activityGroupIds1 =
        getActivityGroupIdsFromActivitiesListingResponse(
          activitiesListingQueryInfo.data
        );

      fetchActivityGroupsAvailability(activityGroupIds1);
    }
  }, [activitiesListingQueryInfo.data]);

  return (
    <>
      {activitiesListingQueryInfo.isLoading ? (
        <Loader />
      ) : (
        <>
          {!activitiesListingQueryInfo.data?.[0].data.length && (
            <ActivitiesListingNoResults
              hasFiltersApplied={getHasFiltersApplied(filters)}
              shouldUseGreyBg={shouldUseGreyBg}
            />
          )}
          <div className="mb-8 grid grid-cols-1 gap-y-4 sm:grid-cols-2 sm:gap-x-6 sm:gap-y-8">
            {activitiesListingQueryInfo.data?.map((page, i) => (
              <React.Fragment key={i}>
                {page.data.map(activityGroup => (
                  <div
                    key={activityGroup._id}
                    className={cn(
                      "group relative flex flex-col overflow-hidden rounded-lg bg-white hover:bg-gray-50",
                      !shouldUseGreyBg && "border border-gray-200",
                      shouldUseGreyBg && "shadow"
                    )}
                    data-cy="listing-activity-item"
                  >
                    <ActivityListItem
                      activityGroup={activityGroup}
                      overallAvailability={
                        activityAvailabilityData?.[activityGroup._id]
                      }
                      isIframe={isIframe}
                      client={client}
                    />
                  </div>
                ))}
              </React.Fragment>
            ))}
          </div>
          {activitiesListingQueryInfo.canFetchMore && (
            <div className="flex justify-center">
              <SubmitButton
                isLoading={Boolean(activitiesListingQueryInfo.isFetchingMore)}
                onClick={() => activitiesListingQueryInfo.fetchMore()}
                disabled={Boolean(activitiesListingQueryInfo.isFetchingMore)}
                isFullWidth
                variant="white"
              >
                Load more
              </SubmitButton>
            </div>
          )}
        </>
      )}
    </>
  );
};
